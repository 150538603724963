export default {
  heading: 'Calistoga, sans-serif',
  subheading: 'Bangers',
  body: 'Noto Sans Mandaic, sans-serif',
  body2: 'Futura, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Lato',
  googleFonts: [
    'Merriweather:100,200,300,400,500,600,700,800,900',
    'Comfortaa',
    'Noto Sans Mandaic:100,200,300,400,500,600,700,800,900',
    'Lobster',
    'Righteous',
    'Oleo Script',
    'Calistoga',
    'Bangers',
    'Lato:300,400,700'
  ],
  customFamilies: ['Amelie', 'Corinthian Light', 'Futura'],
  customUrls: [
    'https://www.gonation.biz/fonts/amelie/amelie.css',
    'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    'https://www.gonation.biz/fonts/futura/futura.css'
  ]
}
