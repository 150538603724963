export default {
  text: '#201f1fd1',
  text2: '#a7a57a',
  primary: '#ce1414',
  secondary: '#444444',
  background: 'white',
  backgroundPrimary: '#48636a',
  backgroundSecondary: '#603d34',
  backgroundTertiary: '#768e74',
  light: '#FFF',
  dark: '#020303'
}
